import React, { useState } from 'react'
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons"

const Form = function(){
 
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://formspree.io/xeqrklwk",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

    return (     
      <section className="px-6 mx-auto md:mt-16 mx-auto mt-5 container ">
      <h1 className="md:p-3 md:mr-32 md:-ml-12 pb-1 text-xl font-semibold border-b-4 border-blue-600 text-blue-600">
        Contact
        </h1>
      <form className=" p-5" 
      onSubmit={handleOnSubmit}
      action="https://formspree.io/xeqrklwk"  
      method="post">
        {/* <div className="bg-white p-2 rounded-full">

        <FontAwesomeIcon icon={faEnvelopeOpen} className="fa-4x ml-2"/>
        </div> */}
        
          <label htmlFor="email" className="block text-gray-800 text-xs align-text-bottom p-1 mt-2">
            Email
          </label>
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            name="email"
            type="email" required
            />
        
          <label htmlFor="message" className="block text-gray-800 text-xs align-text-bottom p-1 mt-3">
            Message
          </label>
          <input
            type="textarea"
            className="shadow  border h-24 rounded w-full py-2 px-3 text-gray leading-tight focus:outline-none focus:shadow-outline"
            id="message"
            name="message"
            required
            rows="10"
            
            />
          <div className="text-right">
            <button type="submit" className="bg-blue-600 hover:bg-blue-400 text-white p-2 mt-3 rounded" disabled={serverState.submitting}>
          Submit

        </button>
              </div>
            <div className="w-full text-right">
            {serverState.status && (
              <div className="bg-green-500 text-white p-1 block text-lg mt-2 rounded-lg text-center">
              <p className={!serverState.status.ok ? "errorMsg" : ""}>
                {serverState.status.msg}
              </p>
        </div>
        )}

            
            </div>
        
      </form>
   
    </section>
    )
}



export default Form