import React from "react"
import Layout from "../gatsby-theme-blog/components/layout"
import Footer from "../custom-components/footer"
import Form from '../custom-components/form'
import AboutMe from '../custom-components/aboutMe'

import { Helmet } from "react-helmet"
import '../custom-components/scss/base.scss'


export default class MyForm extends React.Component {
  
  render() {
   
    return (
    <Layout>
      <Helmet>

      <title>Contact me</title>

    </Helmet>
      <div className="md:w-2/5 mx-auto">

      <AboutMe />
     <Form />
      </div>
     <section className="mt-16 mx-auto md:w-2/5 text-center p-2">

     
     <div className="text-base leading-loosew-3/4 p-2 md:px-12 md:my-4  mx-auto">
        <p className="tracking-wide opacity-75" >
          Please use the contact form or send me an email to <a href="mailto:nut@geekynut.com" className=" hover:text-blue-500 text-blue-800 font-bold">nut@geekynut.com</a> if you have any queries, suggestions or you wish to collaborate. 
        </p>

     </div>
     </section>
        
    
    <Footer />
  </Layout>

);
}


}